<template>
  
  <div>
       <div class="header">
             <ul class="left_menu">
                <div class="item"> 
                        <li class="a"><router-link to='/index4/js44'> 职位管理</router-link></li>
                    <li class="active a">审核</li>
                   
                </div>
                 <div  class="item">  
                        <li class="a" ><router-link to="/index4/xiaoxi44" style="display:flex;    align-items: center">  <i class=" el-icon-message-solid" style="color:#0034FF;width:25px;height:25px;border-radius:100%;background: #f5f5f5;text-align:center;line-height:25px;margin-right:5px"></i> <p style="width:15px;height:15px;background:red;text-align:center;font-size:12px;line-height:15px;border-radius:100%;color:#fff;margin-left:-10px;margin-top:-3px" v-if='result !==0' >{{result}}</p> 消息通知</router-link> </li>
                  <li style="cursor:pointer;" @click="dialogFormVisible = true">技术总监<i class="el-icon-caret-bottom"></i>  </li>
                </div>    

                                        <el-dialog class="kks" title="个人信息" style="text-align:center" :visible.sync="dialogFormVisible">
                        <el-form :model="info">
                            <el-form-item label="昵称" :label-width="formLabelWidth">
                            <el-input v-model="info.name" autocomplete="off">  <el-button slot="append" @click="a11()">修改</el-button></el-input>
                         
                            </el-form-item>
                            <el-form-item label="登录账号" :label-width="formLabelWidth">
                             <el-input v-model="info.phone" autocomplete="off"  :disabled="true"></el-input>
                            </el-form-item>
                             <el-form-item label="登录密码" :label-width="formLabelWidth">
                             <el-input v-model="password3" placeholder="输入旧密码"  autocomplete="off">  <el-button slot="append"  @click="a22()">修改</el-button></el-input>
                            </el-form-item>
                            <el-form-item label="新密码" v-show="password2" :label-width="formLabelWidth">
                             <el-input v-model="password" autocomplete="off">  </el-input>
                            </el-form-item>
                        </el-form>
                        <div slot="footer" class="dialog-footer">
                            <el-button type="primary" @click="tuichu" style="width:100%">退出登录</el-button>
                        </div>
                        </el-dialog>  
             </ul>
        </div>
         <div class="search"></div>
            <div class="content">
                    <p style="margin:20px 0">审核列表</p>
                    
                          <el-table
                    :border="true"
                    :data="tableData"
                :header-cell-style="{background:'#FAFAFA'}"
                    style="width: 100%">
                    
                    <el-table-column
                    :show-overflow-tooltip="true" 
                
                    prop="name"
                    label="姓名"
                    width="100">
                    </el-table-column>
 

                    <el-table-column
                        prop="phone" 
                    label="手机号"
                        :show-overflow-tooltip="true" 
                    >
                    </el-table-column>

                    <el-table-column
                    
                        label="证书"
                      >
                        <template slot-scope="scope">
                            <el-button @click="a(scope.row)" type="warning" size="small">查看</el-button>
                         
                        </template>
                        </el-table-column>

                         <el-table-column
                    
                        label="所属地区"
                      >
                        <template slot-scope="scope">
                            <el-button
                        size="mini"
                            type="primary"
                          
                        @click="find(scope.$index, scope.row)">查看</el-button>
                         
                        </template>
                        </el-table-column>

                          <el-table-column
                        label="审核结果"
                      >
                        <template slot-scope="scope">
                            <el-button @click="success(scope.row)" type="success" size="small">通过</el-button>
                              <el-button @click="faile(scope.row)" type="info" size="small">拒绝</el-button>
                        </template>
                        </el-table-column>
                         <el-table-column
     
      label="查看详情"
   >
     <template slot-scope="scope">
          <el-button
          size="mini"
            type="info"
             
           @click="handleEdit55(scope.row)">查看详情</el-button>

           <el-dialog title="详情列表" :visible.sync="dialogTableVisible55">
               <div style="display:flex">
                         <div style="width:33%;text-align:center"> <p>一寸个人照片</p>  <img :src=zjzpurl alt="" style="width:200px;height:200px"></div> 
                 <div  style="width:33%;text-align:center">   <p>身份证照片（正）</p>  <img :src=zsurl alt="" style="width:200px;height:200px"></div> 
                  <div  style="width:33%;text-align:center">  <p>身份证照片（反）</p>  <img :src=idurl1 alt="" style="width:200px;height:200px"></div> 
               </div>
                       
   
</el-dialog>
     </template>
                         </el-table-column>
                </el-table>
             <el-dialog
                        title="审核不通过"
                        :visible.sync="centerDialogVisible1"
                        width="30%"
                        center>
                      
                   <el-input
                   style="margin-bottom:20px"
                type="textarea"
                :rows="5"
                placeholder="请输入内容"
                v-model="textarea">
                </el-input>
                                    
                          <el-button type="primary" @click="ck">确 定</el-button>
                        </el-dialog>
                    <el-dialog
                                        title="服务区域"
                                        :visible.sync="dialogVisiblefind1"
                                        width="30%"
                                       >
                                        <p>{{options1}}</p>
                                       
                                        </el-dialog>
                        <el-dialog
                        title="证书"
                        class="zs"
                        :visible.sync="centerDialogVisible"
                        
                        center>
                      <viewer :images="img">
                      <img @click="centerDialogVisible = false" :src=img alt="" style="width:200px;margin:0 auto">
                      </viewer>
                        </el-dialog>

                                     
                        <el-dialog
                        title="调律师审核"
                        :visible.sync="dialogVisiblefindl"
                        width="50%"
                        style="text-align:center"
                        center>
                      
                     <el-input v-model="input1" placeholder="请添加平台证件编号"></el-input>
                     
                           <el-button type="success" @click="sure()" style="margin:20px auto;display:block">确认</el-button>
                       
                        </el-dialog>

                        
            </div>
  </div>
</template>

<script>
export default {
 
    methods:{
        sure(){
              this.$axios({
                        method: "post",
                        url: "/s/backend/api/check_user",
                        params: {
                        accessToken : localStorage.getItem('token')
                        },
                        data:{  "flag":1,
                        "jobnumber": this.jobnumber,
                        'key_2':this.input1,
                        "reason": ""},
                        }) .then(res=>{

                            if(res.data.code == 0){
                                     this.$message({
                            message: '审核通过',
                          
                            });
                                this.dialogVisiblefindl = false
                              this.$axios({
                        method: "get",
                        url: "/s/backend/api/check_list",
                        params: {
                        accessToken : localStorage.getItem('token'),
                        roleid:6
                        },
                     
                        }) .then(res=>{
                       this.tableData = res.data.data
                 
                        })
                            }else{
                                         this.dialogVisiblefindl = false
                                       this.$message({
                            message: res.data.msg,
                          
                            });
                            }
                        
                        }).catch({})
        },
        tj(row){
this.dialogVisiblefindl=true
this.name1 = row.name
        },
         find(index,row){
    this.dialogVisiblefind1 =true
     this.$axios({
                            method: "get",
                            url: "/s/backend/api/user_service_area",
                            params: {
                            accessToken : localStorage.getItem('token'),
                           "jobnumber":  row.jobnumber ,
                            },
                         
                            }) .then(res=>{
                               this.options1 = res.data.data
                                var arr = []
                               for( var i = 0;i++;i<res.data.data.length){
                                   arr = arr.push(res.data.data[i][0])
                               }
                          
                            })
            .catch(function(){
            
            })  

},
        handleClick(row){

        },
           handleEdit55(row){   
                      this.dialogTableVisible55=true
           

                     this.zjzpurl=row.zjzpurl
                     this.zsurl=row.zsurl
                     this.idurl1=row.idurl1
                   },
        success(row){
           this.dialogVisiblefindl = true
           this.jobnumber = row.jobnumber
           
        },
        faile(row){
            this.centerDialogVisible1= true
            this.ll = row.jobnumber
        },
        ck(){
              this.$axios({
                        method: "post",
                        url: "/s/backend/api/check_user",
                        params: {
                        accessToken : localStorage.getItem('token')
                        },
                        data:{  "flag": 2,
                        "jobnumber": this.ll,
                          'key_2':this.input1,
                        "reason": this.textarea},
                        }) .then(res=>{
                          this.centerDialogVisible1=false
                              this.$axios({
                        method: "get",
                        url: "/s/backend/api/check_list",
                        params: {
                        accessToken : localStorage.getItem('token'),
                        roleid:6
                        },
                     
                        }) .then(res=>{
                       this.tableData = res.data.data
                 
                        })
                        }).catch({})
        },
          tuichu(){
            this.dialogFormVisible = false;
            window.localStorage.removeItem('token')
                     this.$router.push({ name: 'login'})
        },
        a(row){
            console.log(row)
            this.img=row.zsurl
            this.centerDialogVisible = true
        }
    },
 data(){
        return{
            ll:'',
            name1:'',
            input1:'',
            dialogVisiblefindl:false,
            dialogVisiblefind1:false,
            options1:'',
                dialogTableVisible55:false,
             zjzpurl:'',
                zsurl:'',
                idurl1:'',
            img:'',
            textarea:'',
              centerDialogVisible: false, centerDialogVisible1: false,
            datal1:[],
            datall:[],
                dialogFormVisible:false, form: {
          name: '',
          region: '',
          date1: '',
          date2: '',
          delivery: false,
          type: [],
          resource: '',
          desc: ''
        },
        formLabelWidth: '120px',
        info:[],
infos:[],jobnumber:'',
            activeName: 'first',
    
                 tableData: [],
                    data1:[],
                    data2:[],
                 password:'',
                   password2:false,
                  password3:''
        }
        },
       
        mounted(){
                                    
this.$axios.get("/s/backend/message/get_message_unread", {
　  　params: { 'accessToken':localStorage.getItem('token')}
})
  .then(res=>{
         this.result = res.data.result
          
        })
        .catch(function(){
           
        })   
  this.$axios({
                        method: "get",
                        url: "/s/backend/api/check_list",
                        params: {
                        accessToken : localStorage.getItem('token'),
                        roleid:6
                        },
                     
                        }) .then(res=>{
                       this.tableData = res.data.data
                 
                        })
    
 









  

               this.$axios.get("/s/backend/api/user_detail", {
        params: { 'accessToken':localStorage.getItem('token'),
        'jobnumber':localStorage.getItem('jobnumber')
        }
        })

     .then(res=>{
          this.info = res.data.data
           
        })
        .catch(function(){
           
        })
        }
}
</script>

<style scoped>

/deep/.el-dialog__wrapper{
    top:-50px
}
/deep/.zs .el-dialog__wrapper{
      width: 15%;
      text-align: center;
}


/deep/ .kks .el-dialog{
        width: 25%;

    float: right;
  
}
/deep/.el-dialog{
        width: 40%;

    
  
}
/deep/ .el-dialog--center .el-dialog__body{
    text-align: center;
}
    .content{
        background: #f5f5f5;
        padding:10px 40px;
    }
 .content .content_ul{
            display: flex;
            justify-content: space-between;
        }
         .content .content_ul li{
             width: 32%;padding-left: 15px;
         }




.item li:hover{
    color: #0034FF;
}
.item li a:hover{
    color: #0034FF !important;
}
   /deep/.el-table th > .cell {
      text-align: center;

    }

    /deep/.el-table .cell {
      text-align: center;

    }

/deep/.el-tabs--card>.el-tabs__header{
    border-bottom: none !important;
}

/deep/ .el-tabs--card>.el-tabs__header .el-tabs__item{
    border-bottom:1px solid #E4E7ED;}
/deep/ .el-tabs__item.is-active{
    border:1px solid #409EFF;
    border-left:1px solid #409EFF !important;
      border-bottom:1px solid #409EFF !important;
}
    .header{
            min-height: 57px;
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-between;
    }
    .header .left_menu{
        display: flex;   width: 100%;  padding:0 40px;
        justify-content: space-between;
    }
    .header .left_menu .item{
        display: flex;
        align-items: center;
    }
        .header .left_menu .item .a{
            margin-right: 60px;  
            cursor: pointer;
        }
           .header .left_menu .item li a{
               text-decoration: none;color: #000;font-size: 16px;
font-weight: 500;
           }

        .header .active{
            color: #0034FF;
            position:relative;
        }
             .header .active::before{
                      content: "";
   
         left: 0;
    position: absolute;
    bottom:-120%;
    height:3px;
    width:30px;
    background: #0034FF;
            }
</style>